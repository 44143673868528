.forgot-password {
  h2,
  p,
  img {
    margin-bottom: 30px;
  }
  input {
    margin-bottom: 15px;
    width: 100%;
    min-width: 0;
    border: 2px solid var(--black);
    font-size: var(--big);
    font-weight: var(--font-weight-black);
    padding: 10px 8px;
    margin-top: 4px;
    color: var(--black);
  }
  input[type="submit"] {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 14px 0;
    font-size: var(--big);
    color: white;
    background-color: var(--black);
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  p {
    font-size: var(--normal);
    line-height: 1.3;
    margin-bottom: 40px;
  }
  .resetfail {
    opacity: 0;
    visibility: hidden;

    h3 {
      margin-bottom: 10px;
    }
    i {
      font-size: 32px;
      margin-bottom: 10px;
    }
    a {
      font-size: 18px;
      font-weight: var(--font-weight-black);
      color: var(--black);
      text-decoration: underline;
    }

    &.action {
      visibility: visible;
      opacity: 1;
    }
    .sucess,
    .fail {
      padding: 20px 10px;
    }
    .sucess {
      background-color: var(--green);
    }
    .fail {
      background-color: var(--red);
    }
  }
}
