.scoretable {
  margin-bottom: 30px;
}

.scoretable__table {
  width: 100%;
  border-collapse: collapse;
  font-size: var(--normal);
}

.scoretable__table th,
.scoretable__table tr {
  text-align: right;
}

.scoretable__table thead {
  font-size: var(--normal);
  font-weight: var(--font-weight-black);
  border: 0;
  border-bottom: 2px solid var(--black);
  border-spacing: 0;
}

.scoretable__table th {
  padding-bottom: 2px;
  padding-left: 5px;
  border: 0;
  border-spacing: 0;
}

.scoretable__row {
  border-bottom: 1px solid var(--black);
}

.scoretable__row td {
  padding: 12px 0 10px 5px;
  position: relative;
}

//mark older scores
.scoretable__table tbody tr:nth-child(n + 21) {
  background-color: var(--darkergray);

  button,
  i {
    background-color: var(--darkergray);
  }
}

.isgood.scoretable__row .scoretable__headline-scoredifferential:before {
  border-bottom: 5px solid var(--brand-primary);
  content: "";
  width: 40px;
  position: absolute;
  bottom: 6px;
  left: 0;
}

.scoretable__table button {
  background-color: white;
}

.scoretable__headline-scoredifferential {
  width: 16%;
  text-align: left !important;
  font-weight: var(--font-weight-black) !important;
}

.scoretable__headline-edit {
  width: 9%;
}

.scoretable__headline-delete {
  width: 9%;
}

.scoretable__headline-order {
  width: 10%;
}

.scoretable__headline-score,
.scoretable-text-input {
  width: 20%;
}

.scoretable__headline-courserating,
.scoretable-courserating-input {
  width: 18%;
}

.scoretable__headline-slope,
.scoretable-slope-input {
  width: 18%;
}

.scoretable-text-input,
.scoretable-courserating-input,
.scoretable-slope-input {
  display: none;
}

td.scoretable__headline-score,
td.scoretable__headline-courserating,
td.scoretable__headline-slope {
  padding-right: 4px;
}

.edit_mode {
  .scoretable__headline-score,
  .scoretable__headline-courserating,
  .scoretable__headline-slope {
    display: none;
  }
}

.edit_mode {
  .scoretable-text-input,
  .scoretable-courserating-input,
  .scoretable-slope-input {
    display: table-cell;
  }
}

.edit_mode.scoretable__row input {
  width: 100%;
  max-width: 50px;
  text-align: right;
  border: 1px solid var(--black);
  padding-right: 3px;
}

.edit_mode.scoretable__row i.fa-pen:before {
  content: "\f00c";
}

.edit_mode.scoretable__row td {
  padding: 11px 0 9px 0;
  position: relative;
}
