.account {
  text-align: left;

  h2 {
    font-size: var(--big);
    line-height: 1.3;
    margin-bottom: 10px;
  }
  p {
    font-size: var(--normal);
    line-height: 1.3;
    margin-bottom: 20px;
  }
  .dangerzone {
    margin-top: 100px;
  }
  .bg {
    display: none;
    visibility: hidden;
  }
  .profilephoto__wrap {
    margin-top: 10px;
    margin-bottom: 50px;
    p {
      margin-top: 10px;
    }
    i {
      padding-right: 5px;
    }
    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    input {
      margin-top: 10px;
    }
    button {
      margin-top: 30px;
      background-color: var(--green);
      display: none;
      &.toggle_show {
        display: flex;
      }
    }
  }
  .profilephoto__div {
    border-radius: 100%;
    width: 250px;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid var(--black);
    overflow: hidden;
    margin-bottom: 10px;
    // img {
    //   width: 100%;
    //   height: auto;
    //   max-width: 200px;
    // }
  }
  .delete__confirmation {
    display: none;
    visibility: hidden;
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: center;
    padding: 50px 25px;
    background-color: white;
  }

  button {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 320px;
    width: 100%;
    padding: 14px 0;
    font-size: var(--big);
    color: white;
    background-color: var(--black);
    margin-bottom: 10px;
    font-weight: var(--font-weight-black);
    i {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
    }
    &#delete__toggler {
      background-color: var(--red);
    }
    &.deletehandler {
      background-color: var(--red);
    }
  }
}

.edit_mode .account {
  .delete__confirmation {
    display: flex;
    visibility: visible;
    z-index: 11;
    align-items: center;
    flex-direction: column;
  }
  .bg {
    display: block;
    visibility: visible;
    background-color: var(--black);
    opacity: 0.95;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
