.header__branding{
  z-index: 1000000;
  img{
      width: 100%;
        max-width: 150px;
        height: auto;
        // fill:red;
    @include viewport-4{
      max-width: 200px;
    }



  }
}
