.datenschutz{
  text-align: left;
  line-height: 1.4;
  h3, h4{
    margin: 20px 0;
  }
  ol, ul{
    margin: 20px 0 20px 40px;
  }
}
