.header__profilephoto {
  z-index: 1111111111111;
  overflow: visible;
  img {
    width: auto;
    height: 35px;
  }
}

.header__profilephoto-border {
}
