.verify-account {
  .btn-primary {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 320px;
    width: 100%;
    padding: 14px 0;
    font-size: var(--big);
    color: white;
    background-color: var(--black);
    margin: 0 auto;
    margin-bottom: 10px;
    font-weight: var(--font-weight-black);
    i {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  i.sucess,
  i.failure {
    font-size: 64px;
  }
  i.sucess {
    color: var(--green);
  }

  i.failure {
    color: var(--red);
  }
}
