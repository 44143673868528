header.header {
  margin: 0;
  padding: 14px 0 10px 0;
  background-color: var(--black);
  z-index: 100;
  height: 60px;
  box-shadow: var(--shadow);
  position: fixed;
  @include viewport-9 {
    height: inherit;
  }
}

.hide-for-now {
  display: none !important;
}

.header__inside {
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: space-between;
}

.header__branding {
}

.header__navigation {
}

.header__navigation-toggle {
}
