.donate__columns{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include viewport-7{
    flex-direction: row;
  }
}

.donate__column{
  width: 100%;
  @include viewport-7{
    width: 50%;
  }
}

.donate__column-1{
  text-align: left;
  width: 100%;
  @include viewport-7{
    width: 55%;
    padding-right: 80px;
  }
}

.donate__column-2{
  width: 100%;
  margin-top: 20px;
  @include viewport-7{
    width: 45%;
    margin-top: 0;
    
  }
}


.donate__option-columns{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include viewport-7{
    flex-direction: row;
  }
}

.donate__option-column{
  width: 100%;
  @include viewport-7{
    width: 50%;
  }
}


.donate__text-box{
  h2{
      font-size: var(--huge);
      margin-bottom: 25px;
      
  }
  p{
    line-height: 1.4;
    font-size: var(--normal);
  }
}

.donate__donate-box{
  background-color: #fffdfa;
  padding: 25px;
  border: 2px solid var(--darkergray);
  h2{
    margin-bottom: 25px;
    padding-bottom: 25px;
    position: relative;
    white-space: wrap;
    text-transform: uppercase;
    letter-spacing: -1px;
    @include viewport-7{
      white-space: nowrap;
    }
    &:after{
      content:"";
      background-color: var(--darkergray);
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.donate__column{
  p{
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
  }
}


.donate__other{
  text-align: left;
  opacity: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;

  input{
    padding: 10px;
    font-size: 20px;
    max-width: 100%;
    border: 2px solid var(--darkergray);
  }
  &.active{
    opacity: 1;
    height: auto;
    overflow: visible;
    visibility:visible;
  }
}


.donate__button-wrap{
 
  button{
    margin-top: 20px;
    margin-bottom: 5px;
    border: 0;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    background:#0073b8;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-transform: uppercase;
    width: 100%;
    font-weight: var(--font-weight-black);
    letter-spacing: 2px;
    i{
      margin-right: 5px;
    }
}
}


.credit-card-icon {
  display: block;
  width: 32px;
  height: 22px;
  background: url("/images/payments.png");
  background-size: 224px 22px;
}
.donation-widget__card-icons {
  text-align: center;
  padding-top: 10px;
}

.donation-widget__card-icons .credit-card-icon+.credit-card-icon {
  margin-left: 6px;
}

.donation-widget__card-icons .credit-card-icon {
  display: inline-block;
  vertical-align: top;
}

.credit-card-icon.visa {
  background-position: -100% 0;
}

.credit-card-icon.mastercard {
  background-position: -200% 0;
}

.credit-card-icon.discover {
  background-position: -300% 0;
}

.credit-card-icon.american-express {
  background-position: -500% 0;
}

.credit-card-icon.paypal {
  background-position: -600% 0;
}





.donate__radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 20px;
  text-align: left;
  user-select: none;
  padding-top: 2px;
}

/* Hide the browser's default radio button */
.donate__radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: white;
  border-radius: 50%;
  border: 3px solid var(--darkergray);
}

/* On mouse-over, add a grey background color */
.donate__radio:hover input ~ .checkmark {
  background-color: #bf9a5e;
}

/* When the radio button is checked, add a blue background */
.donate__radio input:checked ~ .checkmark {

}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.donate__radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.donate__radio .checkmark:after {
  top: 10px;
  left: 10px;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #bf9a5e;
}
