.emptystate {
  margin-bottom: 40px;
  margin-top: 40px;
}
.emptystate__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptystate h2 {
  color: var(--black);
}

.emptystate i {
  font-size: 62px;
  color: var(--brand-primary);
  margin-bottom: 20px;
}

.emptystate svg {
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}
