.header__navigation-toggle{
  @include viewport-9 {


  }

}


.header__navigation-toggle {
  position: absolute;
  right: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  transition: background 500ms, top 250ms;
  z-index: 110;
  height: 30px;
  width: 50px;
}

.header__navigation-toggle__bar {
  display: block;
  position: absolute;
  right: 0;
  top: 0px;
  transition: all 200ms;
  will-change: left, top;
  span{
    overflow: hidden;
  }

  &:nth-child(1) {
    top: 0px;

  }

  &:nth-child(2) {
    top: 16px;
  transform: rotate(180deg);

  }
  svg{
    width: 100%;
    height: auto;}
}


//active
body.header__navigation__mobile-toggle--open {
  overflow: hidden;
  .header__navigation-toggle__bar {

  }

  .header__navigation-toggle__bar:nth-child(1) {
    transform: rotate(45deg);
    top: 14px;

  }

  .header__navigation-toggle__bar:nth-child(2) {

      transform: rotate(135deg);

  }




}
