.register-account {
  h2,
  p,
  img {
    margin-bottom: 30px;
  }
  input {
    margin-bottom: 15px;
    width: 100%;
    min-width: 0;
    border: 2px solid var(--black);
    font-size: var(--big);
    font-weight: var(--font-weight-black);
    padding: 10px 8px;
    margin-top: 4px;
    color: var(--black);
  }
  input[type="submit"] {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 14px 0;
    font-size: var(--big);
    color: white;
    background-color: var(--black);
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  input[name="checkbox"] {
    width: initial;
    margin-right: 10px;
  }
  p {
    font-size: var(--normal);
    line-height: 1.3;
    margin-bottom: 40px;
  }
  .registersucess {
    position: absolute;
    background-color: var(--green);
    width: 100%;
    max-width: 500px;
    z-index: 10;
    top: 40px;
    padding: 30px 10px 30px 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
    &.sucess {
      opacity: 1;
      visibility: visible;
    }
    i {
      color: white;
      font-size: 40px;
      margin-bottom: 10px;
    }
    p {
      color: white;
      padding: 0;
      margin: 0;
    }
    h3 {
      color: white;
      margin-bottom: 10px;
    }
  }
  // ./* Customize the label (the container--checkbox) */
  .container--checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    padding-top: 5px;
  }

  // /* Hide the browser's default checkbox */
  .container--checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    z-index: 1111;
  }

  // /* On mouse-over, add a grey background color */
  .container--checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }

  // /* When the checkbox is checked, add a blue background */
  .container--checkbox input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  // /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  // /* Show the checkmark when checked */
  .container--checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  // /* Style the checkmark/indicator */
  .container--checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
