.intro {
  text-align: left;
  h1 {
    font-size: var(--huge);
    line-height: 1.3;
    margin-bottom: 10px;
  }
  h2 {
    font-size: var(--normal);
    line-height: 1.3;
    font-weight: normal;
    margin-bottom: 4px;
  }
  p {
    font-size: var(--normal);
    line-height: 1.3;
    margin-bottom: 40px;
  }
  span {
    padding: 2px 14px;
    color: white;
    background-color: var(--brand-primary);
  }
}
