.myfooter{
background-color: var(--black);
color: var(--white);
text-align: center;
padding: 40px 0;
margin: 50px 0 0 0;
i{
    color: var(--brand-primary);
}
p{
  line-height: 1.5;
  margin-top:30px;
    margin-bottom:0px;
}
p,a,ul,li{
  color: var(--white);
  font-size: var(--small-text);
}
a{
  color: var(--brand-primary);
  font-weight: var(--font-weight-headline-bold);
}
ul{
  margin: 0;
  list-style-type: none;
  li{
    margin: 5px 0;
    a{
      color: var(--white);
      font-weight: var(--font-weight-headline);
    }
  }
}
}
