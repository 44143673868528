.container {
  position: relative;
  width: 100%;
}

.container__inside {
  display: block;
  position: relative;
  max-width: 950px;
  margin: 0 15px;
  @include viewport-9 {
    margin: 0 auto;
  }
}

.container__inside.container__inside-small {
  max-width: 500px;
  margin: 0 15px;
  @include viewport-4 {
    margin: 0 auto;
  }
}

.container__inside-wide {
  display: block;
  position: relative;
  max-width: 100%;
  margin: 0 25px;
  @include viewport-7 {
    margin: 0 40px;
  }
}

.anchor-element {
  position: relative;
  top: -70px;
  @include viewport-9 {
    top: -85px;
  }
}

.container--margin {
  margin: 50px 0;
}
.container--margin-huge {
  margin: 200px 0 0 0;
}
